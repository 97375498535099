<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="d-flex">
          <div class="my-3">
            <span class="h4 text-secondary mr-auto my-auto"
              >Liste des capacités
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 d-flex justify-content-end align-btn-table-2">
        <custom-add-button
          :disabled="disabled"
          class="btn h1 mb-2 btn-secondary d-flex align-items-center"
          variant="secondary"
          text="Ajouter capacité"
          @click="addContributerInputs"
        ></custom-add-button>
      </div>
      <div class="col-12">
        <hr class="mt-1 mb-3 bg-secondary" />
      </div>
    </div>
    <b-table
      responsive
      :items="tableItems"
      :fields="technicalCapacity.tableFields"
      :busy="loading"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(type)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.item["@type"] === "CapaciteTechnique" ? "Technique" : "Métier"
        }}</span>
        <div v-else>
          <v-select
            append-to-body
            :options="['Métier', 'Technique']"
            placeholder="Type capacité"
            v-model="type"
            @input="changelist"
            class="font-weight-normal"
            style="padding-right: 0 !important"
            :input-attr="{
              class: {
                'is-invalid': $v.type.$error && $v.type.$dirty,
                ' form-control': true,
              },
            }"
            :class="{
              'is-invalid form-control p-0': $v.type.$error && $v.type.$dirty,
            }"
          >
            <template #no-options> Liste vide</template>
          </v-select>
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.type.$dirty"
          >
            {{ !$v.type.required ? "Champ obligatoire" : "" }}
          </span>
        </div>
      </template>
      <template v-slot:cell(name)="data">
        <b-link
          v-if="!data.item.hasOwnProperty('editMode')"
          :to="{
            name: 'show-technical-capacity',
            params: {
              id: data.item.id,
              slug: data.item.slug || 'abcde',
            },
          }"
        >
          {{ data.value }}
        </b-link>
        <div v-else>
          <!-- {{ list }} -->
          <v-select
            append-to-body
            :options="list"
            label="text"
            :reduce="(respo) => respo.value"
            placeholder="Capacité technique"
            v-model="capacite"
            class="font-weight-normal"
            @input="capacitedata"
            style="padding-right: 0 !important"
            :input-attr="{
              class: {
                'is-invalid': $v.capacite.$error && $v.capacite.$dirty,
                ' form-control': true,
              },
            }"
            :class="{
              'is-invalid form-control p-0':
                $v.capacite.$error && $v.capacite.$dirty,
            }"
          >
            <template #no-options> Liste vide</template>
          </v-select>
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.capacite.$dirty"
          >
            {{ !$v.capacite.required ? "Champ obligatoire" : "" }}
          </span>
        </div>
      </template>
      <template v-slot:cell(niveau)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          {{ data.value }}
        </span>
        <div v-else>
          <b-form-input
            disabled
            type="number"
            v-model="niveau"
            placeholder="Niveau"
          ></b-form-input>
        </div>
      </template>
      <template #cell(actions)="data">
        <table-actions
          :actions="disabled ? [] : ['delete']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @confirmItem="confirmItem"
          @cancelItem="cancelItem"
          @deleteItem="deleteItem(data.item)"
        ></table-actions>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
// import { concat } from "lodash";

export default {
  components: { TableActions, CustomAddButton },
  props: {
    items: Array,
    loading: Boolean,
    disabled: Boolean,
  },
  data: () => ({
    sortBy: "name",
    perPage: 5,
    currentPage: 1,
    tabledata: [],
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    niveau: "",
    capacite: "",
    type: "",
    list: [],
    sortable: true,
    technicalCapacity: {
      tableFields: [
        { key: "type", label: "Type capacité", sortable: true },

        { key: "name", label: "Capacités", sortable: true },

        {
          key: "niveau",
          label: "Niveau",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    },
    tableItems: [],
  }),
  validations: {
    type: {
      required,
    },
    capacite: {
      required,
    },
  },
  computed: {
    rows() {
      return this.items ? this.items.length : 0;
    },
    ...mapGetters("capaciteTechnique", ["CAPACITES_TECHNIQUES"]),
    ...mapGetters("capaciteMetier", ["CAPACITE_METIERS"]),
    CapaciteList() {
      return this.CAPACITES_TECHNIQUES.map((data) => ({
        text: data.name,
        value: data["@id"],
      }));
    },
    ...mapGetters("application", ["APPLICATIONS"]),
    ...mapGetters("composant", ["COMPOSANTS"]),
    ...mapGetters("composant", ["COMPOSANT"]),
    ...mapGetters("application", ["APPLICATION"]),
    ComposantList() {
      return this.COMPOSANTS.map((data) => ({
        text: data.name,
        value: data.id,
      }));
    },
    ApplicationList() {
      return this.APPLICATIONS.map((data) => ({
        text: data.name,
        value: data["@id"],
      }));
    },
    capacitesList() {
      return this.CAPACITES_TECHNIQUES.filter(
        (capacite) =>
          !this.tableItems.find((capa) => capa["@id"] == capacite["@id"])
      ).map((data) => ({
        text: data.name,
        value: data.id,
      }));
    },
    capacitesMetiersList() {
      return this.CAPACITE_METIERS.filter(
        (capacite) =>
          !this.tableItems.find((capa) => capa["@id"] == capacite["@id"])
      ).map((data) => ({
        text: data.name,
        value: data.id,
      }));
    },
  },
  methods: {
    deleteItem: function (item) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          if (item.type == "Technique") {
            let filter = this.tableItems
              .filter((x) => x["@id"] !== item["@id"])
              .map((data) => data["@id"]);
            let val = {
              id: this.$route.params.id,
              capacites: filter,
            };
            this.$store.dispatch("cadre/updateCadre", val).then(() => {
              this.$store.dispatch("cadre/fetchCadre", this.$route.params.id);
              Swal.fire("Supprimé!", "", "success");
            });
          }
        }
      });
    },

    capacitedata() {
      if (this.type == "Technique") {
        let capaciteTechnique = this.CAPACITES_TECHNIQUES.find(
          ({ id }) => id == this.capacite
        );

        window.console.log(capaciteTechnique);
        this.niveau = capaciteTechnique.niveau;
      } else if (this.type == "Métier") {
        let capaciteMetier = this.CAPACITE_METIERS.find(
          ({ id }) => id == this.capacite
        );
        window.console.log(capaciteMetier);
        this.niveau = capaciteMetier.niveau;
      }
    },

    changelist() {
      if (!this.type) {
        this.capacite = "";
        this.list = [];
      } else {
        if (this.type == "Métier") {
          this.capacite = "";
          this.list = this.capacitesMetiersList;
        } else if (this.type == "Technique") {
          this.capacite = "";
          this.list = this.capacitesList;
        }
      }
    },

    addContributerInputs() {
      var data = this.tableItems.some((contribute) => contribute.editMode);
      if (data == false) {
        this.type = "";
        this.capacite = "";
        this.niveau = "";
        this.currentPage = 1;
        this.tableItems = [
          {
            editMode: true,
          },
          ...this.tableItems,
        ];
      }
    },
    confirmItem: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.type == "Technique") {
          window.console.log(this.capacitedata);
          let item = this.tableItems
            .filter((data) => data["@id"])
            .map((data) => data["@id"]);
          this.$store
            .dispatch("composant/fetchComposant", this.capacite)
            .then(() => {
              let capaciteTechnique = this.CAPACITES_TECHNIQUES.find(
                ({ id }) => id == this.capacite
              );

              item.push(capaciteTechnique["@id"]);
              var datax = {
                id: this.$route.params.id,
                capacites: item,
              };
              this.$store.dispatch("cadre/updateCadre", datax).then(() => {
                this.$store.dispatch("cadre/fetchCadre", this.$route.params.id);
                Swal.fire({
                  title: "La capacité est bien créée !",
                  type: "success",
                });
                this.$v.$reset();
              });
            });
        } else if (this.type == "Métier") {
          window.console.log(this.capacitedata);
          let item = this.tableItems
            .filter((data) => data["@id"])
            .map((data) => data["@id"]);
          this.$store
            .dispatch("composant/fetchComposant", this.capacite)
            .then(() => {
              let capaciteMetier = this.CAPACITE_METIERS.find(
                ({ id }) => id == this.capacite
              );

              item.push(capaciteMetier["@id"]);
              var datax = {
                id: this.$route.params.id,
                capacites: item,
              };
              this.$store.dispatch("cadre/updateCadre", datax).then(() => {
                this.$store.dispatch("cadre/fetchCadre", this.$route.params.id);
                Swal.fire({
                  title: "La capacité est bien créée !",
                  type: "success",
                });
                this.$v.$reset();
              });
            });
        }
      }
    },

    cancelItem: function () {
      if (this.type == "" && this.capacite == "") {
        this.$store.dispatch("cadre/fetchCadre", this.$route.params.id);
      } else {
        this.type = "";
        this.capacite = "";
        this.niveau = "";
      }
    },
  },
  mounted() {
    this.tableItems = [...this.items];
  },
  watch: {
    items() {
      this.tableItems = [...this.items];
    },
  },

  created() {
    this.$store.dispatch("capaciteTechnique/fetchAllCapacites");
    this.$store.dispatch("capaciteMetier/fetchAllCapaciteMetiers");
  },
};
</script>

<style></style>
