<template>
  <div class="d-flex justify-content-center">
    <b-button
      v-if="actions.includes('show')"
      @click="$emit('showItem')"
      variant="light"
      pill
      size="sm"
      style="width: 40px; height: 40px"
      class="d-flex align-items-center justify-content-center"
      ><i class="mdi mdi-eye"></i
    ></b-button>
    <b-button
      v-if="actions.includes('edit') && !editMode"
      @click="$emit('editItem')"
      variant="light"
      pill
      size="sm"
      style="width: 40px; height: 40px"
      class="d-flex align-items-center justify-content-center"
      ><i class="mdi mdi-pencil-outline"></i
    ></b-button>
    <b-button
      v-if="editMode || actions.includes('confirm')"
      @click="$emit('confirmItem')"
      variant="light"
      pill
      size="sm"
      style="width: 40px; height: 40px"
      class="d-flex align-items-center justify-content-center"
      ><i class="mdi mdi-check"></i
    ></b-button>
    <b-button
      v-if="actions.includes('delete') && !editMode"
      @click="$emit('deleteItem')"
      variant="light"
      pill
      size="sm"
      style="width: 40px; height: 40px"
      class="d-flex align-items-center justify-content-center"
      ><i class="mdi mdi-delete"></i
    ></b-button>
    <b-button
      v-if="actions.includes('transfer') && !editMode"
      @click="$emit('transferItem')"
      variant="light"
      pill
      size="sm"
      style="width: 40px; height: 40px"
      class="d-flex align-items-center justify-content-center"
      ><i class="mdi mdi-arrow-right-bold"></i
    ></b-button>
    <b-button
      v-if="editMode"
      @click="$emit('cancelItem')"
      variant="light"
      pill
      size="sm"
      style="width: 40px; height: 40px"
      class="d-flex align-items-center justify-content-center"
      ><i class="mdi mdi-close-octagon"></i
    ></b-button>
  </div>
</template>

<script>
export default {
  name: "TableActions",
  props: {
    actions: Array,
    editMode: Boolean,
  },
};
</script>
