<template>
  <b-avatar
    v-b-tooltip.hover="
      responsable ? responsable.firstname + ' ' + responsable.lastname : '-'
    "
    :src="responsable ? IMG_URL + responsable.avatarUrl : ''"
    class="mr-1"
    style="background-color: #adb5bd; border-color: #adb5bd"
  >
    <div
      v-if="!responsable || !responsable.avatarUrl"
      style="
        width: 30px;
        height: 30px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      {{
        responsable
          ? responsable.firstname[0].toUpperCase() + responsable.lastname[0].toUpperCase()
          : "-"
      }}
    </div>
  </b-avatar>
</template>

<script>
import { IMG_URL } from "@/helpers/services/api";
export default {
  props: {
    responsable: Object,
  },
  data() {
    return {
      IMG_URL,
    };
  },
};
</script>
