<template>
  <div>
    <div class="row mb-3">
      <div class="col-12 col-md-6">
        <div class="d-flex">
          <div class="my-3">
            <span class="h4 text-secondary mr-auto my-auto"
              >Cadres associés
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 d-flex justify-content-end align-btn-table-2">
        <custom-add-button
          :disabled="disabled"
          class="btn h1 mb-2 btn-secondary d-flex align-items-center"
          variant="secondary"
          text="Ajouter un cadre"
          @click="addContributerInputs"
        ></custom-add-button>
      </div>
      <div class="col-12">
        <hr class="mt-1 mb-3 bg-secondary" />
      </div>
    </div>

    <b-table
      responsive
      :items="items"
      :fields="technicalCapacity.tableFields"
      :busy="loading"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      bordered
      striped
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(type)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value ? data.value : "-"
        }}</span>
        <div v-else>
          <v-select
            append-to-body
            :options="cadresTypes"
            @input="change"
            placeholder="Type capacité"
            v-model="type"
            class="font-weight-normal"
            style="padding-right: 0 !important"
            :input-attr="{
              class: {
                'is-invalid': $v.type.$error && $v.type.$dirty,
                ' form-control': true,
              },
            }"
            :class="{
              'is-invalid form-control p-0': $v.type.$error && $v.type.$dirty,
            }"
          >
            <template #no-options> Liste vide</template>
          </v-select>
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.type.$dirty"
          >
            {{ !$v.type.required ? "Champ obligatoire" : "" }}
          </span>
        </div>
      </template>
      <template v-slot:cell(name)="data">
        <b-link
          v-if="!data.item.hasOwnProperty('editMode')"
          :to="{
            name: 'show-principe-ref',
            params: {
              id: data.item.id,
              slug: data.item.slug || 'abcde',
            },
          }"
        >
          {{ data.value }}
        </b-link>
        <div v-else>
          <v-select
            append-to-body
            :options="cadreslist"
            label="text"
            :reduce="(respo) => respo.value"
            placeholder="Libellé cadre"
            v-model="cadre"
            class="font-weight-normal"
            style="padding-right: 0 !important"
            :input-attr="{
              class: {
                'is-invalid': $v.cadre.$error && $v.cadre.$dirty,
                ' form-control': true,
              },
            }"
            :class="{
              'is-invalid form-control p-0': $v.cadre.$error && $v.cadre.$dirty,
            }"
          >
            <template #no-options> Liste vide</template>
          </v-select>
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.cadre.$dirty"
          >
            {{ !$v.cadre.required ? "Champ obligatoire" : "" }}
          </span>
        </div>
      </template>

      <template #cell(actions)="data">
        <table-actions
          :actions="disabled ? [] : ['delete']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @confirmItem="confirmItem(data.item)"
          @cancelItem="cancelItem"
          @deleteItem="deleteItem(data.item.id)"
        ></table-actions>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { uniq } from "lodash";
export default {
  components: { TableActions, CustomAddButton },
  props: {
    items: Array,
    loading: Boolean,
    disabled: Boolean,
  },
  validations: {
    type: {
      required,
    },
    cadre: {
      required,
    },
  },
  data: () => ({
    sortBy: "name",
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    datatable: [],
    // cadreslist: [],
    cadre: "",
    type: "",
    sortable: true,
    technicalCapacity: {
      tableFields: [
        { key: "type", label: "Type cadre", sortable: true },

        { key: "name", label: "Libellé cadre", sortable: true },

        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    },
  }),
  computed: {
    rows() {
      return this.items ? this.items.length : 0;
    },
    ...mapGetters("cadre", ["CADRE_ENTREPRISES"]),
    cadreslist() {
      return this.CADRE_ENTREPRISES.filter(
        (data) =>
          data.type == this.type &&
          parseInt(data.id) !== parseInt(this.$route.params.id) &&
          !this.items.find((item) => item["@id"] === data["@id"])
      ).map((data) => ({
        text: data.name,
        value: data["@id"],
      }));
    },
    cadresTypes() {
      // :options="['Pattern', 'Règle', 'Politique', 'Principe']"

      return uniq(
        this.CADRE_ENTREPRISES.filter(
          (data) =>
            parseInt(data.id) !== parseInt(this.$route.params.id) &&
            !this.items.find((item) => item["@id"] === data["@id"])
        ).map(({ type }) => type)
      );
    },
  },
  created() {
    this.$store.dispatch("cadre/fetchAllCadres");
    this.datatable = this.items;
  },
  methods: {
    uniq,
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          var data = this.items
            .filter((data) => data.id !== id)
            .map((data) => data["@id"]);
          let item = {
            id: this.$route.params.id,
            souscadres: data,
          };
          this.$store.dispatch("cadre/updateCadre", item).then(() => {
            Swal.fire("Supprimé!", "", "success");
            this.$store.dispatch("cadre/fetchCadre", this.$route.params.id);
          });
        }
      });
    },

    confirmItem: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var data = this.items.map((data) => data["@id"]);
        data.push(this.cadre);
        data.splice(0, 1);
        let item = {
          id: this.$route.params.id,
          souscadres: data,
        };
        this.$store.dispatch("cadre/updateCadre", item).then(() => {
          Swal.fire({
            title: "Le cadre est mis à jour !",
            type: "success",
          });
          this.$store
            .dispatch("cadre/fetchCadre", this.$route.params.id)
            .then(() => {
              this.type = "";
              this.cadre = "";
              this.$v.reset();
            });
        });
      }
    },
    change() {
      if (!this.type) {
        this.cadreslist = [];
        this.cadre = "";
        this.type = "";
      } else {
        if (this.type == "Principe") {
          this.cadre = "";
          // this.cadreslist = this.cadreData;
          window.console.log(this.cadreData, "cadreData");
        } else {
          this.cadre = "";
          // this.cadreslist = this.cadreData;
        }
      }
    },
    addContributerInputs() {
      var data = this.items.some((contribute) => contribute.editMode);
      if (data == false) {
        this.currentPage = 1;
        this.items.unshift({
          editMode: true,
        });
      }
    },

    cancelItem: function () {
      if (this.type == "" && this.cadre == "") {
        this.items.splice(0, 1);
      } else {
        this.type = "";
        this.cadre = "";
      }
    },
  },

  addFunction(item) {
    this.technicalCapacity.tableItems[item.itemIndex].function = item.value;
  },
  deleteItem: function (id) {
    Swal.fire({
      title: "Etes-vous sûr(e) ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Annuler",
      confirmButtonText: "Confirmer",
    }).then((result) => {
      if (result.value) {
        var data = this.datatable.filter((data) => data.id == id);
        this.$store.dispatch("cadre/updateCadre", data).then(() => {
          this.$store.dispatch("cadre/fetchCadre", this.$route.params.id);
        });
      }
    });
  },
};
</script>

<style></style>
