<template>
  <div>
    <div class="row mb-3">
      <div class="col-12 col-md-6">
        <div class="d-flex">
          <div class="my-3">
            <span class="h4 text-secondary mr-auto my-auto"
              >Liste des systèmes
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 d-flex justify-content-end align-btn-table-2">
        <custom-add-button
          :disabled="disabled"
          class="btn h1 mb-2 btn-secondary d-flex align-items-center"
          variant="secondary"
          text="Ajouter un système"
          @click="addContributerInputs"
        ></custom-add-button>
      </div>
      <div class="col-12">
        <hr class="mt-1 mb-3 bg-secondary" />
      </div>
    </div>
    <b-table
      responsive
      :items="items"
      :fields="technicalCapacity.tableFields"
      :busy="loading"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(type)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value
        }}</span>
        <div v-else>
          <v-select
            append-to-body
            :options="['Application', 'Composant Technique']"
            placeholder="Type capacité"
            v-model="type"
            @input="changelist"
            class="font-weight-normal"
            style="padding-right: 0 !important"
            :input-attr="{
              class: {
                'is-invalid': $v.type.$error && $v.type.$dirty,
                ' form-control': true,
              },
            }"
            :class="{
              'is-invalid form-control p-0': $v.type.$error && $v.type.$dirty,
            }"
          >
            <template #no-options> Liste vide</template>
          </v-select>
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.type.$dirty"
          >
            {{ !$v.type.required ? "Champ obligatoire" : "" }}
          </span>
        </div>
      </template>
      <template v-slot:cell(systeme)="data">
        <b-link
          v-if="!data.item.hasOwnProperty('editMode')"
          :to="{
            name:
              data.item.type == 'Composant Technique'
                ? 'show-composant'
                : 'show-application',
            params: {
              id: data.item.id,
              slug: data.item.slug || 'abcde',
            },
          }"
        >
          {{ data.value }}
        </b-link>
        <div v-else>
          <v-select
            append-to-body
            :options="list"
            label="text"
            :reduce="(respo) => respo.value"
            placeholder="Système"
            v-model="systeme"
            class="font-weight-normal"
            style="padding-right: 0 !important"
            @input="systemedata"
            :input-attr="{
              class: {
                'is-invalid': $v.systeme.$error && $v.systeme.$dirty,
                ' form-control': true,
              },
            }"
            :class="{
              'is-invalid form-control p-0':
                $v.systeme.$error && $v.systeme.$dirty,
            }"
          >
            <template #no-options> Liste vide</template>
          </v-select>
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.systeme.$dirty"
          >
            {{ !$v.systeme.required ? "Champ obligatoire" : "" }}
          </span>
        </div>
      </template>
      <template v-slot:cell(po)="data">
        <TableResponsable
          v-if="!data.item.hasOwnProperty('editMode')"
          :responsable="data.value"
        ></TableResponsable>
        <TableResponsable v-else :responsable="po"></TableResponsable>
      </template>
      <template v-slot:cell(capacite)="data">
        <div v-if="!data.item.hasOwnProperty('editMode')">
          <b-link
            v-if="data.value"
            :to="
              data.value
                ? {
                    name: 'show-technical-capacity',
                    params: {
                      id: data.value.id || '1',
                      slug: data.value.slug || '621dd50372819',
                    },
                  }
                : false
            "
            >{{ data.value ? data.value.name : "-" }}
          </b-link>
          <span v-else>-</span>
        </div>
        <b-form-input
          placeholder="Capacite"
          disabled
          v-else
          v-model="capacite"
        ></b-form-input>
      </template>
      <template v-slot:cell(taux)="data">
        <b-progress
          v-if="!data.item.hasOwnProperty('editMode')"
          max="100"
          show-progress
          height="15px"
          style="background-color: #cacccd !important"
        >
          <b-progress-bar
            :value="data.value || 0"
            :label="`${data.value || 0}%`"
            :style="`background-color: ${
              data.value > 20 && data.value <= 70
                ? '#f5f245'
                : data.value > 70
                ? '#23d92c'
                : '#f5ba45'
            }`"
          ></b-progress-bar>
        </b-progress>
        <b-progress
          v-else
          max="100"
          show-progress
          height="15px"
          style="background-color: #cacccd !important"
        >
          <b-progress-bar
            :value="taux || 0"
            :label="`${taux || 0}%`"
            :style="`background-color: ${
              taux > 20 && taux <= 70
                ? '#f5f245'
                : taux > 70
                ? '#23d92c'
                : '#f5ba45'
            }`"
          ></b-progress-bar>
        </b-progress>
      </template>

      <template #cell(actions)="data">
        <table-actions
          :actions="disabled ? [] : ['delete']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @confirmItem="confirmItem(data.item)"
          @cancelItem="cancelItem"
          @deleteItem="deleteItem(data.item)"
        ></table-actions>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { required } from "vuelidate/lib/validators";
import TableResponsable from "../../../../components/custom/TableResponsable";
import { mapGetters } from "vuex";
import { orderBy } from "lodash";
import { APPLICATION_URL, COMPOSANT_URL } from "@/helpers/constURL";
export default {
  components: { TableResponsable, TableActions, CustomAddButton },
  props: {
    items: Array,
    loading: Boolean,
    disabled: Boolean,
  },
  data: () => ({
    sortBy: "id",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    type: "",
    po: null,
    capacite: "",
    datatable: [],
    taux: null,
    list: [],
    systeme: "",
    sortable: true,
    technicalCapacity: {
      tableFields: [
        { key: "type", label: "Type système", sortable: true },
        { key: "systeme", label: "Système", sortable: true },
        { key: "po", label: "PO", sortable: true },
        { key: "capacite", label: "Capacité", sortable: true },
        { key: "taux", label: "Taux de remplissage", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    },
  }),
  validations: {
    type: {
      required,
    },
    systeme: {
      required,
    },
  },
  computed: {
    rows() {
      return this.items ? this.items.length : 0;
    },
    ...mapGetters("application", ["APPLICATIONS"]),
    ...mapGetters("composant", ["COMPOSANTS"]),
    ...mapGetters("composant", ["COMPOSANT"]),
    ...mapGetters("application", ["APPLICATION"]),
    ComposantList() {
      return this.COMPOSANTS.map((data) => ({
        text: data.name,
        value: data.id,
      }));
    },
    ApplicationList() {
      return this.APPLICATIONS.map((data) => ({
        text: data.name,
        value: data.id,
      }));
    },
  },
  methods: {
    orderBy,
    addFunction(item) {
      this.technicalCapacity.tableItems[item.itemIndex].function = item.value;
    },
    confirmItem: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.type == "Application") {
          var item = this.items
            .filter((data) => data["@type"] == "Application")
            .map((data) => data["@id"]);
          item.push(`/api/${APPLICATION_URL}/` + this.systeme);
          var data = {
            id: this.$route.params.id,
            applications: item,
          };
          this.$store.dispatch("cadre/updateCadre", data).then(() => {
            this.$store.dispatch("cadre/fetchCadre", this.$route.params.id);
            Swal.fire("Le système d'application est bien créé", "", "success");
            this.type = "";
            this.systeme = "";
            this.list = [];
            this.po = "";
            this.taux = "";
            this.capacite = "";
          });
        } else {
          var item1 = this.items
            .filter((data) => data["@type"] == "Composant")
            .map((data) => data["@id"]);
          item1.push(`api/${COMPOSANT_URL}/` + this.systeme);
          var data1 = {
            id: this.$route.params.id,
            composants: item1,
          };
          this.$store.dispatch("cadre/updateCadre", data1).then(() => {
            this.$store
              .dispatch("cadre/fetchComposant", this.$route.params.id)
              .then(() => {
                this.$store.dispatch("cadre/fetchCadre", this.$route.params.id);
                Swal.fire(
                  "Le système d'application est bien créé",
                  "",
                  "success"
                );
                this.type = "";
                this.systeme = "";
                this.list = [];
                this.po = "";
                this.taux = "";
                this.capacite = "";
              });
          });
        }
      }
    },
    addContributerInputs() {
      var data = this.items.some((contribute) => contribute.editMode);
      if (data == false) {
        this.currentPage = 1;
        this.items.unshift({
          editMode: true,
        });
      }
    },
    cancelItem: function () {
      if (this.type == "" && this.systeme == "") {
        this.items.splice(0, 1);
      } else {
        this.type = "";
        this.systeme = "";
        this.po = null;
        this.capacite = "";
        this.taux = null;
      }
    },
    changelist() {
      if (!this.type) {
        this.systeme = "";
        this.list = [];
        this.po = "";
        this.taux = "";
        this.capacite = "";
      } else {
        if (this.type == "Application") {
          this.systeme = "";
          this.list = this.ApplicationList.filter(
            (data) =>
              !this.items.find(
                (application) =>
                  application.id === data.value &&
                  application.type == "Application"
              )
          );
        } else {
          this.systeme = "";
          this.list = this.ComposantList.filter(
            (data) =>
              !this.items.find(
                (composant) =>
                  composant.id === data.value &&
                  composant.type == "Composant Technique"
              )
          );
        }
      }
    },
    systemedata() {
      if (this.type == "Application") {
        this.$store
          .dispatch("application/fetchApplication", this.systeme)
          .then(() => {
            this.taux = this.APPLICATION.taux;
            this.po = this.APPLICATION.po;
            var x = this.APPLICATION.capaciteSis
              ? this.APPLICATION.capaciteSis.length == 1
                ? this.APPLICATION.capaciteSis[0]
                : this.APPLICATION.capaciteSis
                    .filter((data) => data.parent)
                    .map((data) => data.parent)[0]
              : "-";
            this.capacite = x.name ? x.name : null;
          });
      } else {
        this.$store
          .dispatch("composant/fetchComposant", this.systeme)
          .then(() => {
            this.taux = this.COMPOSANT.taux;
            this.po = this.COMPOSANT.archictecteTechnique;
            var cap = this.COMPOSANT.capacites
              ? this.COMPOSANT.capacites.length == 1
                ? this.COMPOSANT.capacites[0]
                : this.COMPOSANT.capacites
                    .filter((data) => data.parent)
                    .map((data) => data.parent)[0]
              : "-";

            this.capacite = cap.name ? cap.name : null;
          });
      }
    },
    deleteItem: function (item) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          if (item.type == "Composant Technique") {
            let filter = this.items
              .filter(
                (x) =>
                  x.id !== parseInt(item.id) && x.type == "Composant Technique"
              )
              .map((data) => data["@id"]);
            let val = {
              id: this.$route.params.id,
              composants: filter,
            };
            this.$store.dispatch("cadre/updateCadre", val).then(() => {
              this.$store
                .dispatch("cadre/fetchComposant", this.$route.params.id)
                .then(() => {
                  this.$store.dispatch(
                    "cadre/fetchCadre",
                    this.$route.params.id
                  );
                  Swal.fire("Supprimé!", "", "success");
                });
            });
          } else {
            let filter = this.items
              .filter(
                (x) => x.id !== parseInt(item.id) && x.type == "Application"
              )
              .map((data) => data["@id"]);
            let val = {
              id: this.$route.params.id,
              applications: filter,
            };
            this.$store
              .dispatch("cadre/fetchComposant", this.$route.params.id)
              .then(() => {
                this.$store.dispatch("cadre/updateCadre", val).then(() => {
                  this.$store.dispatch(
                    "cadre/fetchCadre",
                    this.$route.params.id
                  );
                  Swal.fire("Supprimé!", "", "success");
                });
              });
          }
        }
      });
    },
  },
  created() {
    this.$store.dispatch("application/fetchAllApplications");
    this.$store.dispatch("composant/fetchAllComposants");
  },
};
</script>

<style></style>
